import React, {useState} from 'react';
import axios from 'axios';
import config from '../config.json';
import {useNavigate} from 'react-router-dom';
import $ from 'jquery';
import { Link } from 'react-router-dom';

window.jQuery = $;
window.$ = $;
global.jQuery = $;
window.bootstrap = require('bootstrap');
var bootprompt = require('bootprompt');

export default function Login() {
  const [user, setUser] = useState("");
  const [pass, setPass] = useState("");
  const navigate = useNavigate();

  function changeUser(event) {
    setUser(event.target.value);
  }

  function changePass(event) {
    setPass(event.target.value);
  }

  function requestLogin() {
    axios.post(config.BASE_URL + "/api/check-login", {user, pass})
    .then(res => {
      if (res.data.status == "OK") {
        console.log(res.data.data);
        navigate("/home");
      }
      else {
        bootprompt.alert(res.data.error);
      }
    })
    .catch(err => {
      bootprompt.alert(err.message);
    });
  }

  const handleKeyDown = e => {
    if (e.keyCode === 13) {
      requestLogin();
    }
  };

  return (
    <div className="login-box">
      <div>
        <h1>PMC</h1>
        <h5>Productivity Management Center</h5>
        <h3>Login</h3>
      </div>
      <div className="form-group">
          <label>Username</label>
          <input type="text" className="form-control" value={user} onChange={changeUser} onKeyDown={handleKeyDown} />
      </div>
      <div className="form-group mb-4">
          <label>Password</label>
          <input type="password" className="form-control" value={pass} onChange={changePass} onKeyDown={handleKeyDown} />
      </div>
      <div style={{textAlign: "right"}}>
          <button className="btn btn-primary" onClick={requestLogin}>Login</button>
      </div>
      <div style={{textAlign: "center"}}>
        <Link to="/register">Register new account</Link>
      </div>
    </div>
  )
}
